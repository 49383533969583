import $ from 'jquery';

$(() => {
    // Navigation Touch Geräte
    $('ul.navbar-nav > li.nav-menu > a').on('touchend', function (e) {
        if (!$(this).parent().hasClass('hovered')) {
            e.preventDefault();
            $(this).parent().toggleClass('hovered');
            $(this).parent().siblings().removeClass('hovered');
        }
    });
});
