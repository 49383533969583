import { AlpineComponent } from '@/alpine/helper';
import axios from 'axios';

export const http = axios.create({
    baseURL: '/',
    responseType: 'json',
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json;charset=UTF-8',
    },
    transformRequest: [
        function (data, headers) {
            if (data instanceof FormData) {
                headers.setContentType('multipart/form-data');
            }

            return data;
        },
    ],
});

http.interceptors.response.use(
    (response) => response,
    (error) => {
        return Promise.reject(error.response);
    }
);

export class FormComponent extends AlpineComponent {
    public submitting: boolean = false;
    public success: boolean = false;
    public errors: any = {};

    init() {
        this.errors = {};
        this.success = false;
        this.submitting = false;

        // @ts-ignore
        this.$nextTick(() => {
            const grecaptcha = (window as any).grecaptcha as any | undefined;

            if (typeof grecaptcha === 'object') {
                grecaptcha.ready(() => {
                    // Render the captcha on existing elements
                    Array.from(document.querySelectorAll('.g-recaptcha')).map(e => {
                        try {
                            grecaptcha.render(e);
                        } catch (e) {
                            //
                        }
                    });
                });
            }
        });
    }

    submit(e: Event) {
        e.preventDefault();

        const form = e.target as HTMLFormElement;
        const data = new FormData(form);

        this.submitting = true;

        http.request({
            url: form.getAttribute('action') || '',
            method: form.getAttribute('method') || '',
            data,
        })
            .then((res) => {
                this.success = true;
                (window as any).formSuccess = true;
            })
            .catch((err: any) => {
                if (err.status === 500) {
                    alert('Currently the form does not work. Please contact us on a different way.');
                } else {
                    this.errors = err.data.error;
                }
            })
            .finally(() => {
                this.submitting = false;
            });
    }
}
