import { AlpineComponent } from '@/alpine/helper';

export class FixedHeaderComponent extends AlpineComponent {
    public fixed: boolean = false;
    public height: number = 0;
    public offset: number = 0;
    public scrollContainer: Window | HTMLElement = window;

    shouldBeFixed() {
        return (this.offset + 100) < (this.scrollContainer instanceof Window ? this.scrollContainer.scrollY : this.scrollContainer.scrollTop);
    }

    init() {
        this.offset = this.$refs.navbar?.getBoundingClientRect().bottom ?? 200;
        this.height = this.$refs.navbar?.clientHeight ?? 0;

        const placeholder = this.$refs?.placeholder;

        if (placeholder) {
            placeholder.style.height = this.height + 'px';
        }

        this.scrollContainer.addEventListener('scroll', () => {
            this.fixed = this.shouldBeFixed();
        });

        this.fixed = this.shouldBeFixed();
    }
}
